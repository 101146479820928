<template>
<div class="details">
  <TopBack :title="$t('home.yyxq')"></TopBack>
  <div class="card">
    <p>{{$t('home.ddbh')}}：<span>{{data.orderNo}}</span></p>
    <p>{{$t('home.yysj')}}：<span>{{data.createTime?locale==='en-US'?toHHmmss(data.createTime,-5):toHHmmss(data.createTime,+8):''}}</span></p>
    <p>{{$t('home.yysl')}}：<span>{{data.totalAmount}}</span></p>
    <p>{{$t('home.sssl')}}：<span>{{data.balance}}</span></p>
  </div>
  <h2>{{$t('home.zcjl')}}</h2>
  <van-list
      v-model:loading="loading"
      :finished="finished"
      :finished-text="$t('home.noMore')"
      :loading-text="$t('home.loading')"
      :error-text="$t('home.failed')"
      @load="onLoad"
  >
    <div class="order-items" v-for="(item,i) in list" :key="i" >
      <div class="left">
        <p :class="item.status===3?'red':''">{{getSubStatus(item.status)}}</p>
        <span v-if="locale==='zh-HK'">{{item.name+item.turn+$t('home.q')}}</span>
        <span v-else>{{item.EnNname+item.turn+$t('home.q')}}</span>
      </div>
      <div class="right">
        <p>{{item.amount}}+{{item.interest}}</p>
        <span>{{item.createTime?locale==='en-US'?toHHmmss(item.createTime,-5):toHHmmss(item.createTime,+8):'' }}</span>
      </div>
    </div>
  </van-list>
</div>
</template>
<script setup>
import TopBack from './components/TopBack'
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {toHHmmss} from '../utils/decimalTool'
import {getReservationOrderDetail} from "../api";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
const loading = ref(false);
const finished = ref(false);
const pageNo=ref(1)
const total=ref(0)
const list=ref([])
const data=ref({})
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getHttp()
      loading.value = false;

    }
  }, 1000);
};

onBeforeMount(()=>{
  getHttp()
})
const  route=useRoute()
const getHttp=()=>{
  getReservationOrderDetail({
    "id":route.query.id,
    "pageNo":pageNo.value,
    "pageSize":10,
  }).then(res=>{
    list.value.push(...res.data.orders[0].child_orders)
    data.value=res.data.orders[0].order
    total.value=res.data.page.totalNum
  })
}
const { $t }=getCurrentInstance().proxy
const {locale } = useI18n()
const getSubStatus=(status)=>{
  let text
  switch (status) {
    case 1 :
      text=$t('home.zcSatuts1')
      break
    case 2 :
      text=$t('home.zcSatuts2')
      break
    case 3 :
      text=$t('home.zcSatuts3')
      break
    case 4 :
      text=$t('home.zcSatuts4')
      break
    case 5 :
      text=$t('home.zcSatuts5')
      break
  }
  return text
}
</script>
<style scoped lang="less">
.details{
  padding: 0 17px;
}
.card{
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 18px;
  margin: 9px 0 30px;
  p{
    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 16px;
    color: #7E8D95;
    text-align: left;
    span{
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  p:last-child{
    margin: 0;
  }
}
h2{
  font-weight: 500;
  font-size: 16px;
  color: #7E8D95;
  margin-bottom: 7px;
  text-align: left;
}
.order-items{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 0;
  border-bottom: 1px solid #7E8D95;
  .left{
    display: flex;
    flex-direction: column;
    align-items: start;
    p{
      font-weight: 500;
      font-size: 16px;
      color: #6FFEE8;
      margin: 0 0 10px 0;
    }
    span{
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
    }
  }
  .red{
    color: #FF5A5A;
  }
  .right{
    display: flex;
    flex-direction: column;
    align-items: end;
    p{
      font-weight: bold;
      font-size: 17px;
      color: #6FFEE8;
      margin: 0 0 10px 0;
    }
   span{
     font-weight: 500;
     font-size: 12px;
     color: #7E8D95;
   }

  }
}
</style>
